import { memo, useContext, useMemo } from 'react'

import { Avatar, Badge, Box, Typography } from '@mui/material'
import gql from 'graphql-tag'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'

import { useMutation, useQuery } from '@redwoodjs/web'

import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import Row from '../Row/Row'

const REQUEST_PERSON_ENRICHMENT = gql`
  mutation RequestPersonEnrichment($email: String!) {
    requestPersonEnrichment(email: $email)
  }
`

const GET_PERSON_AVATAR = gql`
  query GetPersonAvatar($email: String!, $workspaceId: String!) {
    getPerson(email: $email, workspaceId: $workspaceId) {
      id
      firstName
      lastName
      photoUrl
    }
  }
`

function emailToDefaultPhoto(str) {
  return `${process.env.HOST}/people/profile_blank.png`
}

function emailToBackgroundColor(str) {
  if (str && str.length === 0) return '#f0f0f0'
  const firstChar = str ? str.charAt(3) : 'M'
  const charCode = firstChar.charCodeAt(0)
  const colors = [
    '#5AB298',
    '#F9A826',
    '#F26D85',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
    '#5AB298',
    '#F9A826',
    '#F2C94C',
    '#A3A0FB',
    '#F26D85',
  ]
  return colors[charCode % colors.length]
}

const GET_CONTACT_BY_EMAIL_FOR_AVATAR = gql`
  query GetCoreContactByEmailForAvatar($email: String!) {
    coreContact(email: $email) {
      id
      photo
      firstName
      lastName
      companyName
      manuallyVerified
      timezone
      linkedInUrl
      title
    }
  }
`

const GET_ORGANIZATION_FOR_PERSON_AVATAR = gql`
  query GetOrganizationForPersonAvatar(
    $domain: String!
    $workspaceId: String!
  ) {
    workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
      name
      photos {
        square
      }
      colors {
        colorVibrant
        colorLightVibrant
      }
    }
  }
`

const ContactAvatar = memo(
  ({
    email,
    size,
    border,
    borderRadius = 100,
    style,
    showVerification = false,
    showSidebar = false,
    showCompany = false,
    useFallback = false,
  }: {
    email?: string
    size?: number
    border?: number
    borderRadius?: number
    style?: React.CSSProperties
    showVerification?: boolean
    showSidebar?: boolean
    showCompany?: boolean
    useFallback?: boolean
  }) => {
    const confirm = useConfirm()
    const { selectedWorkspace, workspacePeople, setSidebarObject } =
      useContext(DayContext)

    const domain = extractEmailDomain(email)

    const personFromWorkspace = useMemo(() => {
      return workspacePeople.find(
        (p) => p.email === email?.toLowerCase().trim()
      )
    }, [workspacePeople, email])

    const { data, error, loading } = useQuery(GET_CONTACT_BY_EMAIL_FOR_AVATAR, {
      variables: { email },
      skip: !email || email === 'assistant@day.ai',
    })

    const { data: personData } = useQuery(GET_PERSON_AVATAR, {
      variables: { email, workspaceId: selectedWorkspace },
      skip:
        !email ||
        email === 'assistant@day.ai' ||
        !selectedWorkspace ||
        !!personFromWorkspace?.photoUrl,
    })

    const { data: orgData } = useQuery(GET_ORGANIZATION_FOR_PERSON_AVATAR, {
      variables: {
        workspaceId: selectedWorkspace,
        domain,
      },
      skip: !domain || !selectedWorkspace,
      onCompleted: ({ workspaceOrganization }) => {
        logger.dev({ workspaceOrganization })
      },
    })

    let photoUrl =
      personData?.getPerson?.photoUrl || personFromWorkspace?.photoUrl

    const [requestPersonEnrichment] = useMutation(REQUEST_PERSON_ENRICHMENT, {
      variables: { email },
    })

    const handleRequestPersonEnrichment = async () => {
      try {
        await confirm({
          title: 'Enrich Person',
          description:
            'Send Day.ai to research and verify this person. The results will appear automatically when complete.',
        })
        toast.promise(requestPersonEnrichment(), {
          loading: 'Requesting person enrichment...',
          success: 'Request underway!',
          error: 'Error requesting person enrichment',
        })
      } catch (e) {
        console.error(e)
      }
    }

    const fontSize: string = size ? `${Math.floor(0.42 * size)}px` : '48px'

    if (!border) border = 0

    const fallbackPhoto = emailToDefaultPhoto(email)

    // Hardcode assistant photo
    if (email === 'assistant@day.ai')
      photoUrl = `${process.env.HOST}/logos/Day 1.png`

    // Use coreContact photo
    if (data && !error && !loading && !photoUrl) {
      photoUrl = data?.coreContact?.photo
    }

    // End with fallback
    if (!photoUrl && useFallback) {
      photoUrl = fallbackPhoto
    }

    const finalBorderRadius = borderRadius.toString() + 'px'

    const initials = useMemo(() => {
      const firstName = personData?.getPerson?.firstName
      if (firstName) {
        const lastName = personData?.getPerson?.lastName
        return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`
          .toUpperCase()
          .trim()
      } else {
        return `${email?.charAt(0) || ''}`.toUpperCase().trim()
      }
    }, [personData, email])

    return (
      <Box
        className="personAvatarBox"
        sx={{
          cursor: showSidebar || showVerification ? 'pointer' : 'default',
          position: 'relative',
          height: `${size}px`,
          width: `${size}px`,
        }}
        onClick={(e) => {
          if (showVerification && !showSidebar) {
            e.stopPropagation()
            handleRequestPersonEnrichment()
          } else if (showSidebar) {
            e.stopPropagation()
            setSidebarObject({
              objectId: email,
              objectType: NativeObjectTypes.Contact,
              properties: {},
            })
          }
        }}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            showCompany &&
            orgData?.workspaceOrganization?.photos?.square && (
              <Avatar
                src={orgData?.workspaceOrganization?.photos?.square}
                sx={{
                  height: `${size / 2.6}px`,
                  width: `${size / 2.6}px`,
                  objectFit: 'cover',
                  border: (theme) =>
                    `${1.5}px solid ${
                      theme.palette.background.paper
                    } !important`,
                }}
              >
                {orgData?.workspaceOrganization?.name?.charAt(0)}
              </Avatar>
            )
          }
        >
          {photoUrl ? (
            <Avatar
              src={photoUrl}
              sx={{
                height: `${size}px`,
                width: `${size}px`,
                background: emailToBackgroundColor(email),
                border: (theme) =>
                  `${border}px solid ${theme.palette.background.paper} !important`,
                fontSize,
                objectFit: 'cover',
                fontWeight: 600,
                borderRadius: finalBorderRadius,
                textDecoration: 'none',
                '&:not(a)': {
                  textDecoration: 'none',
                },
                '& .MuiAvatar-fallback': {},
                ...style,
              }}
            />
          ) : (
            <Avatar
              sx={{
                height: `${size}px`,
                width: `${size}px`,
                borderRadius: finalBorderRadius,
                overflow: 'hidden',
                backgroundColor: (theme) =>
                  orgData?.workspaceOrganization?.colors?.colorVibrant ||
                  theme.palette.text.primary,
              }}
              onClick={() => {
                if (showVerification) {
                  handleRequestPersonEnrichment()
                }
              }}
            >
              <Row
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontSize,
                    height: `calc(${Math.floor(size / 2)}px + ${Math.floor(
                      size / 10
                    )}px)`,
                    color: (theme) => theme.palette.primary.contrastText,
                    fontWeight: 600,
                  }}
                >
                  {initials}
                </Typography>
              </Row>
            </Avatar>
          )}
        </Badge>
      </Box>
    )
  }
)

export default ContactAvatar
