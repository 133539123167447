import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import { Organization } from 'types/graphql'

import ContactTile from '../../ContactTile/ContactTile'
import Row from '../../Row/Row'

const OrganizationNextSteps = ({ org }: { org: Organization }) => {
  const immediateNextSteps =
    org?.overview?.['status/nextSteps']?.immediateNextSteps || []

  const groupedNextSteps = Array.isArray(immediateNextSteps)
    ? immediateNextSteps.reduce((acc, item) => {
        if (item && item.person && item.description) {
          if (!acc[item.person]) {
            acc[item.person] = []
          }
          acc[item.person].push(item.description)
        }
        return acc
      }, {} as Record<string, string[]>)
    : {}

  return (
    groupedNextSteps &&
    Object.keys(groupedNextSteps).length > 0 && (
      <>
        <Row sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h2">Next Steps</Typography>
          <Typography
            sx={{
              fontStyle: 'italic',
              fontSize: '0.7rem',
              color: 'text.secondary',
            }}
          >
            Updated automatically as you do them
          </Typography>
        </Row>
        <Box
          sx={{
            mt: 1,
            mb: 5,
            px: 3,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '8px',
            background: (theme) => theme.palette.background.paper,
          }}
        >
          {Object.entries(groupedNextSteps).map(
            ([person, items], groupIndex) => (
              <Box key={`group_${groupIndex}`}>
                <Row
                  sx={{
                    py: 1,
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                    alignItems: 'top',
                  }}
                >
                  <Box sx={{ width: '172px', minWidth: '172px', pt: 3 }}>
                    <ContactTile email={person} />
                  </Box>
                  <List sx={{ pl: 3 }}>
                    {items.map((item, index) => (
                      <ListItem
                        key={`nextStep_${groupIndex}_${index}`}
                        sx={{ mb: 1 }}
                      >
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </Row>
              </Box>
            )
          )}
        </Box>
      </>
    )
  )
}

export default OrganizationNextSteps
