import { useCallback, useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  darken,
  lighten,
} from '@mui/material'
import {
  RiAddLine,
  RiKanbanView2,
  RiPencilLine,
  RiSettingsLine,
  RiTableLine,
} from '@remixicon/react'
import dayjs from 'dayjs'
import { debounce } from 'lodash'

import { Metadata, useMutation, useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import Row from '../Row/Row'

import OpportunityCreateDialog from './OpportunityCreateDialog/OpportunityCreateDialog'
import PipelineBoard from './PipelineBoard/PipelineBoard'
import PipelineEdit from './PipelineEdit/PipelineEdit'
import PipelineForecast from './PipelineForecast/PipelineForecast'
import PipelineList from './PipelineList/PipelineList'
import PipelineOppTypeFilter from './PipelineOppTypeFilter/PipelineOppTypeFilter'
import PipelineOwnerFilter from './PipelineOwnerFilter/PipelineOwnerFilter'
import PipelineTable from './PipelineTable/PipelineTable'
import PipelineTimePeriodFilter from './PipelineTimePeriodFilter/PipelineTimePeriodFilter'

export type Opportunity = {
  id: string
  title: string
  type: string
  ownerEmail: string
  ownerId: string
  expectedCloseDate: Date
  primaryPerson: any
  createdAt: Date
  updatedAt: Date
  currentStatus: string
  expectedRevenue: number
  domain: string
  organization: any
  stageId: string
  position: number
  workspaceId: string
  notes: any[]
}

type Stage = {
  id: string
  workspaceId: string
  pipelineId: string
  position: number
  title: string
  entranceCriteria: string[]
  likelihoodToClose: number
  opportunities: Opportunity[]
}

type Pipeline = {
  id: string
  workspaceId: string
  title: string
  stages: Stage[]
  ownerCoreContacts: any[]
  opportunityTypes: string[]
}

interface PipelineCreateInput {
  workspaceId: string
  title: string
}

export interface OpportunityUpsertInput {
  workspaceId: string
  stageId: string
  position: number
  title: string
  type: string
  ownerId: string
  ownerEmail: string
  expectedCloseDate: Date
  primaryPerson: string
  currentStatus: string
  expectedRevenue: number
  domain: string
}

interface StageUpsertInput {
  title: string
  pipelineId: string
  position: number
  entranceCriteria: string[]
  likelihoodToClose: number
}

interface PipelineUpdateInput {
  title?: string
}

const GET_PIPELINE = gql`
  query pipeline($id: String!, $workspaceId: String!) {
    pipeline(id: $id, workspaceId: $workspaceId) {
      id
      workspaceId
      title
      hasRevenue
      updatedAt
      opportunityTypes
      stages {
        id
        title
        entranceCriteria
        likelihoodToClose
        opportunities {
          id
          title
          type
          ownerId
          ownerEmail
          expectedCloseDate
          currentStatus
          expectedRevenue
          domain
          position
          organization {
            photoSquare
            name
            overview
          }
          primaryPerson {
            objectId
            objectType
            properties
          }
          notes {
            id
            person
            content
            createdAt
          }
          createdAt
          updatedAt
        }
        position
      }
      ownerCoreContacts {
        firstName
        lastName
        email
        photo
      }
    }
  }
`

const UPDATE_PIPELINE_TITLE = gql`
  mutation updatePipeline($id: String!, $input: PipelineUpdateInput!) {
    updatePipeline(id: $id, input: $input) {
      id
      title
      hasRevenue
      updatedAt
    }
  }
`

const UPDATE_OPPORTUNITY_FROM_PIPELINE = gql`
  mutation updateOpportunity($input: OpportunityUpdateInput!) {
    updateOpportunity(input: $input) {
      id
    }
  }
`

type PipelineViewType = 'board' | 'table' | 'list' | 'forecast'

export const toggleIconStyle = { height: '18px', width: '18px', flexShrink: 0 }
const stageColors = {
  0: '#4CAF50',
  1: '#8BC34A',
  2: '#FF5722',
  3: '#E91E63',
  4: '#9C27B0',
  5: '#673AB7',
  6: '#3F51B5',
  7: '#2196F3',
  8: '#03A9F4',
  9: '#00BCD4',
  10: '#009688',
}

const suggestedStageColors = [
  '#4CAF50',
  '#8BC34A',
  '#FF5722',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
]

export const getStageColor = (stagePosition, suggested = false) => {
  return suggested
    ? suggestedStageColors[stagePosition % 10]
    : stageColors[stagePosition % 10]
}

export const getStageStyle = (stagePosition, suggested = false) => {
  const color = suggested
    ? suggestedStageColors[stagePosition % 10]
    : stageColors[stagePosition % 10]
  return {
    color,
    textAlign: 'left',
    justifyContent: 'left',
    border: `1px solid ${lighten(color, 0.6)}`,
    background: lighten(color, 0.9),
    fontWeight: 500,
    px: '4px',
    py: '4px',
    width: '100%',
    cursor: 'pointer',

    '&:hover': {
      background: lighten(color, 0.7),
      border: `1px solid ${lighten(color, 0.3)}`,
    },
  }
}

export interface PipelineFilters {
  id?: string
  view?: PipelineViewType
  forecastTimePeriod?: 'month' | 'quarter' | 'year'
  owner?: string
  oppType?: string
  table?: {
    pinnedColumns?: string[]
    columns?: {
      visibility?: string[]
      orderedFields?: string[]
    }
    sorting?: {
      field?: string
      direction?: 'asc' | 'desc'
    }
    filters?: any
  }
}

export const pipelineFilterWidths = {
  xs: '64px',
  sm: '96px',
  md: '128px',
  lg: '196px',
  xl: '256px',
}

export const shouldShowOpp = (opportunity, filters) => {
  let shouldShow = true

  if (
    !(
      !filters?.owner ||
      filters?.owner === 'allOwners' ||
      opportunity?.ownerEmail === filters.owner
    )
  ) {
    shouldShow = false
  }

  if (
    !(
      !filters?.oppType ||
      filters?.oppType === 'allOppTypes' ||
      opportunity?.type === filters.oppType
    )
  ) {
    shouldShow = false
  }

  return shouldShow
}

const Pipeline = ({ id, workspaceId, actions = <></> }) => {
  const pipelineViewStorageKey = `pipeline_${id}`

  const { selectedWorkspace } = useContext(DayContext)
  const [editing, setEditing] = useState<string | null>(null)
  const [title, setTitle] = useState<string>('')
  const [titleFocused, setTitleFocused] = useState<boolean>(false)
  const [filters, setFilters] = useState<PipelineFilters | null>(null)
  const [board, setBoard] = useState(null)
  const [opportunities, setOpportunities] = useState<Opportunity[]>([])
  const [stageForCreate, setStageForCreate] = useState<string | null>(null)
  const [updatedAt, setUpdatedAt] = useState<Date | null>(new Date(0))

  const workspaceIdToUse = workspaceId || selectedWorkspace

  const {
    data: pipelineData,
    refetch,
    loading: pipelineLoading,
  } = useQuery(GET_PIPELINE, {
    variables: { id, workspaceId: workspaceIdToUse },
    skip: !id || !workspaceIdToUse,
    onCompleted: (data) => {
      if (data.pipeline.updatedAt > updatedAt) {
        setUpdatedAt(data.pipeline.updatedAt)
      }
      if (
        data.pipeline.stages.flatMap((stage) => stage.opportunities).length ===
        0
      ) {
        setStageForCreate(data.pipeline.stages[0].id)
      }
    },
  })

  const [updatePipeline, { loading: updatePipelineLoading }] = useMutation(
    UPDATE_PIPELINE_TITLE,
    {
      onCompleted: (data) => {
        if (data.pipeline.updatedAt > updatedAt) {
          setUpdatedAt(data.pipeline.updatedAt)
        }
      },
    }
  )

  const debouncedUpdatePipeline = useCallback(debounce(updatePipeline, 700), [
    updatePipeline,
  ])

  const [updateOpportunity, { loading: updateOpportunityLoading }] =
    useMutation(UPDATE_OPPORTUNITY_FROM_PIPELINE, {
      onCompleted: (data) => {
        logger.dev({ data })
      },
    })

  const handleUpdateOpportunity = (opportunity) => {
    setUpdatedAt(new Date())
    updateOpportunity({
      variables: {
        input: {
          ...opportunity,
          pipelineId: id,
          workspaceId: workspaceIdToUse,
        },
      },
    })
  }

  const handleChangeTitle = (title: string) => {
    debouncedUpdatePipeline({
      variables: {
        id: pipelineData.pipeline.id,
        input: {
          title,
          workspaceId: pipelineData.pipeline.workspaceId,
          id: pipelineData.pipeline.id,
        },
      },
    })
  }

  useEffect(() => {
    if (title && pipelineData?.pipeline.title != title) handleChangeTitle(title)
  }, [title])

  const handleChangeOwnerFilter = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        owner: event.target.value,
      }
    })
  }

  const handleChangeTypeFilter = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        oppType: event.target.value,
      }
    })
  }

  const handleChangeWarmthFilter = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        warmth: event.target.value,
      }
    })
  }

  const handleTimePeriodChange = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        forecastTimePeriod: event.target.value,
      }
    })
  }

  const handleTableFiltersChange = (tableFilters) => {
    logger.dev({ tableFilters })

    setFilters((prev) => ({
      ...prev,
      table: {
        ...prev?.table,
        pinnedColumns:
          'pinnedColumns' in tableFilters
            ? tableFilters.pinnedColumns
            : prev?.table?.pinnedColumns,
        columns: {
          visibility:
            tableFilters?.columns && 'visibility' in tableFilters?.columns
              ? tableFilters.columns.visibility
              : prev?.table?.columns?.visibility,
          orderedFields:
            tableFilters?.columns && 'orderedFields' in tableFilters?.columns
              ? tableFilters.columns.orderedFields
              : prev?.table?.columns?.orderedFields,
        },
        sorting:
          'sorting' in tableFilters
            ? tableFilters.sorting
            : prev?.table?.sorting,
        filters:
          'filters' in tableFilters
            ? tableFilters.filters
            : prev?.table?.filters,
      },
    }))
  }

  const handleViewChange = (
    _: React.MouseEvent<HTMLElement>,
    newView: PipelineViewType
  ) => {
    if (newView) {
      setFilters((prev) => {
        return {
          ...prev,
          view: newView,
        }
      })
    }
  }

  useEffect(() => {
    logger.dev({ filters })
    const storageValue = JSON.parse(
      localStorage.getItem(pipelineViewStorageKey) || '{}'
    )

    if (id) {
      if (filters) {
        localStorage.setItem(
          pipelineViewStorageKey,
          JSON.stringify({
            ...storageValue,
            filters: filters,
          })
        )
      } else {
        if (storageValue.filters) {
          setFilters(storageValue.filters)
        }
      }
    }
  }, [filters])

  useEffect(() => {
    const allOpps = []
    logger.dev({ pipelineDataChanged: pipelineData })

    if (
      pipelineData?.pipeline &&
      dayjs(pipelineData.pipeline.updatedAt).isAfter(updatedAt)
    ) {
      if (!titleFocused) {
        setTitle(pipelineData.pipeline.title)
      }

      const boardData = {
        columns: pipelineData.pipeline.stages
          .map((stage, index) => {
            return {
              id: stage.id,
              title: stage.title,
              likelihoodToClose: stage.likelihoodToClose,
              position: index, //stage.position,
              cards:
                [...(stage?.opportunities || [])].map((opportunity, index) => {
                  let currentStatus = opportunity.currentStatus
                  if (
                    opportunity?.organization?.overview?.[
                      'status/currentStatusOneSentence'
                    ]
                  ) {
                    currentStatus =
                      opportunity?.organization?.overview?.[
                        'status/currentStatusOneSentence'
                      ]
                  } else if (
                    opportunity?.organization?.overview?.['status/nextSteps']
                      ?.immediateNextSteps?.length > 0
                  ) {
                    currentStatus =
                      opportunity?.organization?.overview?.['status/nextSteps']
                        ?.immediateNextSteps[0]?.description
                  } else if (
                    opportunity?.primaryPerson?.properties?.[
                      'relationshipSummary/oneSentence'
                    ]
                  ) {
                    currentStatus =
                      opportunity?.primaryPerson?.properties?.[
                        'relationshipSummary/oneSentence'
                      ]
                  }

                  const opp = {
                    id: opportunity.id,
                    workspaceId: pipelineData.pipeline.workspaceId,
                    pipelineId: pipelineData.pipeline.id,
                    hasRevenue: pipelineData.pipeline.hasRevenue,
                    type: opportunity.type,
                    title: opportunity.title,
                    ownerId: opportunity.ownerId,
                    ownerEmail: opportunity.ownerEmail,
                    expectedCloseDate: opportunity.expectedCloseDate,
                    primaryPerson: opportunity.primaryPerson,
                    currentStatus,
                    expectedRevenue: opportunity.expectedRevenue,
                    domain: opportunity.domain,
                    organization: opportunity.organization,
                    position: index, //opportunity.position,
                    createdAt: opportunity.createdAt,
                    updatedAt: opportunity.updatedAt,
                    stageId: stage.id,
                    stageTitle: stage.title,
                    stagePosition: stage.position,
                    notes: opportunity.notes,
                  }
                  allOpps.push(opp)
                  return opp
                }) || [],
            }
          })
          .sort((a, b) => a.position - b.position),
      }

      // set board
      logger.dev({ settingBoard: boardData })
      setBoard(boardData)
      setOpportunities(allOpps)
    }
  }, [pipelineData?.pipeline?.updatedAt])

  const opportunityTypes = board?.columns
    .flatMap((column) => column?.cards?.map((opportunity) => opportunity.type))
    .filter((type, index, self) => self.indexOf(type) === index)

  useEffect(() => {
    if (pipelineData?.pipeline?.ownerCoreContacts?.length <= 1) {
      setFilters((prev) => {
        const newFilters = { ...prev }

        if (newFilters.owner !== 'allOwners') {
          newFilters.owner = 'allOwners'
        }

        return newFilters
      })
    }
  }, [pipelineData?.pipeline?.ownerCoreContacts?.length])

  const view = filters?.view || 'board'

  return (
    pipelineData?.pipeline?.id && (
      <Box
        key={`pipeline_${pipelineData?.pipeline?.id}`}
        sx={{ height: '100%', width: '100%', overflow: 'visible' }}
      >
        <Metadata
          title={title}
          description={`${title} Opportunities Pipeline | Day.ai`}
        />
        <Row
          sx={{
            justifyContent: 'space-between',
            p: 3,
            pl: '34px',
            height: '104px',
          }}
        >
          <Row sx={{ width: '100%', alignItems: 'flex-start' }}>
            <TextField
              variant="standard"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              onFocus={() => setTitleFocused(true)}
              onBlur={() => setTitleFocused(false)}
              sx={{
                width: '100%',
                '& .MuiTypography-root, input': {
                  fontWeight: 600,
                  fontSize: '30px',
                  letterSpacing: '-1.5px',
                },
              }}
              inputProps={{
                padding: '0px',
              }}
              InputProps={{
                disableUnderline: false,
                sx: {
                  '&:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0)',
                  },
                  '&:hover:before': {
                    borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
                  },
                },
                endAdornment: titleFocused ? (
                  <RiPencilLine
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '8px',
                    }}
                  />
                ) : (
                  <></>
                ),
              }}
            />
          </Row>
          <Row
            className={'pipelineControls'}
            sx={{ justifyContent: 'right', mb: '8px' }}
          >
            <Tooltip
              title="Edit pipeline"
              placement="bottom"
              arrow={true}
            >
              <IconButton
                onClick={() => {
                  setEditing('pipeline')
                }}
                sx={{ borderRadius: '3px', mr: 1 }}
              >
                <RiSettingsLine size={24} />
              </IconButton>
            </Tooltip>
            {actions}
            {/*<PipelineWarmthFilter
            onChange={handleWarmthChange}
            filters={filters}
          />*/}
            {false && view === 'table' && (
              <PipelineTimePeriodFilter
                onChange={handleTimePeriodChange}
                filters={filters}
              />
            )}
            <PipelineOppTypeFilter
              oppTypeList={opportunityTypes || []}
              onChange={handleChangeTypeFilter}
              filters={filters}
            />
            {pipelineData?.pipeline?.ownerCoreContacts?.length > 1 && (
              <PipelineOwnerFilter
                ownerList={pipelineData?.pipeline?.ownerCoreContacts || []}
                onChange={handleChangeOwnerFilter}
                filters={filters}
              />
            )}
            <ToggleButtonGroup
              value={view}
              exclusive={true}
              onChange={handleViewChange}
              aria-label="pipeline view type"
              sx={{
                height: '32px',
                '& .MuiToggleButton-root': {
                  height: '32px',
                  py: '4px',
                  fontSize: '0.8rem',
                  textTransform: 'none',
                  width: {
                    xs: '36px',
                    sm: '36px',
                    md: '48px',
                    lg: '56px',
                    xl: '64px',
                  },
                  color: (theme) => theme.palette.text.secondary,

                  '&.MuiToggleButtonGroup-middleButton, &.MuiToggleButtonGroup-lastButton':
                    {
                      borderLeft: '1px solid',
                      borderRight: '1px solid',
                      borderColor: (theme) => theme.palette.divider,
                    },
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.0rem',
                    mr: 1,
                  },
                  '&.Mui-selected': {
                    background: (theme) => theme.palette.action.selected,
                    color: (theme) => theme.palette.text.primary,
                    fontWeight: 600,
                    '&:hover': {
                      background: (theme) =>
                        darken(theme.palette.background.default, 0.1),
                    },

                    '& .MuiSvgIcon-root': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  },
                },
              }}
            >
              <Tooltip
                title="Board view"
                placement="bottom"
                arrow={true}
              >
                <ToggleButton
                  value="board"
                  aria-label="board view"
                >
                  <RiKanbanView2 style={toggleIconStyle} />
                </ToggleButton>
              </Tooltip>

              <Tooltip
                title="View as table"
                placement="bottom"
                arrow={true}
              >
                <ToggleButton
                  value="table"
                  aria-label="table view"
                >
                  <RiTableLine style={toggleIconStyle} />
                </ToggleButton>
              </Tooltip>

              {/*<Tooltip
                title="Activity feed"
                placement="bottom"
                arrow={true}
              >
                <ToggleButton
                  value="list"
                  aria-label="activity feed"
                >
                  <RiFileList2Line style={toggleIconStyle} />
                </ToggleButton>
            </Tooltip>
              <Tooltip
                title="Opportunity forecast"
                placement="bottom"
                arrow={true}
              >
                <ToggleButton
                  value="forecast"
                  aria-label="forecast view"
                >
                  <RiBarChartBoxLine style={toggleIconStyle} />
                </ToggleButton>
            </Tooltip>*/}
            </ToggleButtonGroup>
            <Button
              onClick={() => {
                setStageForCreate(pipelineData?.pipeline?.stages[0]?.id)
              }}
              startIcon={<RiAddLine size={16} />}
              variant="contained"
              disableElevation={true}
              color="secondary"
              size="small"
              sx={{ ml: 1 }}
            >
              Opportunity
            </Button>
          </Row>
        </Row>
        <Box>
          {view === 'board' && board && (
            <Box>
              <PipelineBoard
                pipeline={pipelineData?.pipeline}
                boardData={board}
                refetch={refetch}
                filters={filters}
                pipelineLoading={updatePipelineLoading}
                onUpdate={() => {
                  setUpdatedAt(new Date())
                }}
                onEdit={(columnId) => {
                  logger.dev({ columnId })
                  setEditing(columnId)
                }}
              />
            </Box>
          )}
          {view === 'table' && (
            <>
              {false && opportunities?.length > 0 && (
                <PipelineForecast
                  pipeline={pipelineData?.pipeline}
                  filters={filters}
                />
              )}
              <PipelineTable
                key={`pipelineTable_${pipelineData.id}`}
                opportunities={opportunities}
                owners={pipelineData?.pipeline?.ownerCoreContacts || []}
                filters={filters}
                setTableFilters={handleTableFiltersChange}
                onUpdate={handleUpdateOpportunity}
                pipeline={pipelineData?.pipeline}
                refetch={refetch}
              />
            </>
          )}
          {view === 'list' && <PipelineList opportunities={opportunities} />}
          {view === 'forecast' && (
            <PipelineForecast
              pipeline={pipelineData?.pipeline}
              filters={filters}
            />
          )}
        </Box>
        {stageForCreate && (
          <OpportunityCreateDialog
            pipelineId={pipelineData?.pipeline?.id}
            stageId={stageForCreate}
            refetch={refetch}
            onClose={() => {
              setStageForCreate(null)
            }}
          />
        )}
        <Dialog
          open={!!editing}
          onClose={() => {
            setEditing(null)
          }}
          aria-labelledby="edit-pipeline-title"
          aria-describedby="edit-pipeline-description"
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogContent sx={{ height: 'calc(100vh - 64px)' }}>
            <PipelineEdit
              pipelineId={pipelineData?.pipeline?.id}
              workspaceId={workspaceIdToUse}
              refetch={refetch}
              stageId={editing}
            />
          </DialogContent>
        </Dialog>
      </Box>
    )
  )
}

export default Pipeline
