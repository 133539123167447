import { useCallback, useContext } from 'react'

import { Avatar, Box, Card, Divider, Typography, lighten } from '@mui/material'
import { RiBarChartFill, RiFlashlightFill, RiTimeLine } from '@remixicon/react'
import dayjs from 'dayjs'

import { useAuth } from 'src/auth'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import Row from '../../Row/Row'

const formatTimestampExpectedClose = (timestamp: string): string => {
  const date = dayjs(timestamp)

  return date.format(`MMMM 'YY`)
}

export const formatDealAmount = (value: number): string => {
  if (!value) return '$0'
  return `$${value.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`
}

export const warmthEmoji = {
  all: '🌐',
  hot: '🔥',
  warm: '😎',
  cold: '🧊',
}

const PipelineBoardCard = ({ data, dragging, pipeline, refetch, loading }) => {
  const { currentUser } = useAuth()
  const { setSidebarObject, workAccounts, userCoreContact } =
    useContext(DayContext)

  const orgStatusOneSentence =
    data?.organization?.overview?.['status/currentStatusOneSentence']
  const orgStatusFallback =
    data?.organization?.overview?.['status/highLevelSummary']?.[0] ||
    data?.organization?.overview?.['status/nextSteps']?.nextStepReasoning
  const relationshipStatusOneSentence =
    data?.primaryPerson?.properties?.['relationshipSummary/oneSentence']

  const todosForUser =
    data?.organization?.overview?.['status/nextSteps']?.immediateNextSteps &&
    Array.isArray(
      data?.organization?.overview?.['status/nextSteps']?.immediateNextSteps
    )
      ? data?.organization?.overview?.[
          'status/nextSteps'
        ]?.immediateNextSteps?.filter((todo) =>
          workAccounts
            .map((wa) => wa.email)
            .some((email) => todo.person === email)
        )
      : []

  const todoForUser = todosForUser?.[0]?.description

  const handleMouseUp = useCallback(
    (e) => {
      if (!dragging) {
        e.stopPropagation()
        const opp = {
          objectType: NativeObjectTypes.Opportunity,
          objectId: data.id,
          properties: {
            ...data,
            opportunityTypes: pipeline?.opportunityTypes,
            refetch,
          },
        }
        logger.dev({ opp })
        setSidebarObject(opp)
      }
    },
    [dragging]
  )

  return (
    <>
      <Card
        className={`react-kanban-card dragging-${dragging}`}
        onMouseUp={handleMouseUp}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box>
            <Row>
              <Box sx={{ p: 0, m: 0, height: '36px', width: '36px' }}>
                {data?.domain ? (
                  <DomainAvatar
                    photoUrl={data?.organization?.photoSquare}
                    domain={data?.domain}
                    size={36}
                    borderRadius={6}
                  />
                ) : data?.primaryPerson?.objectId ? (
                  <ContactAvatar
                    email={data?.primaryPerson?.objectId}
                    size={36}
                    borderRadius={6}
                  />
                ) : (
                  <Avatar
                    src={null}
                    sx={{
                      height: '36px',
                      width: '36px',
                      objectFit: 'cover',
                      borderRadius: '6px',
                    }}
                  >
                    <RiBarChartFill style={{ height: '20px', width: '20px' }} />
                  </Avatar>
                )}
              </Box>
              <Box sx={{ pl: '12px' }}>
                <Typography
                  sx={{
                    fontSize: '1.0rem',
                    fontWeight: '600',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: '160px',
                    letterSpacing: '-0.3px',
                  }}
                >
                  {data?.title || data?.organization?.properties?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.7rem',
                    fontWeight: '400',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {data?.type}
                </Typography>
              </Box>
            </Row>
            {todoForUser ? (
              <>
                <Row
                  sx={{
                    my: 2,
                    color: (theme) => theme.palette.secondary.main,
                    background: (theme) =>
                      lighten(theme.palette.secondary.main, 0.9),
                    px: 1,
                    py: '4px',
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: (theme) =>
                      lighten(theme.palette.secondary.main, 0.5),
                  }}
                  gap={1}
                >
                  <ContactAvatar
                    email={currentUser.email}
                    size={16}
                    borderRadius={4}
                  />
                  <Typography
                    sx={{
                      fontSize: '0.7rem',
                      fontWeight: 500,
                      lineHeight: '150%',
                    }}
                  >{`${userCoreContact?.firstName} has ${
                    todosForUser.length
                  } next step${
                    todosForUser.length > 1 ? 's' : ''
                  }`}</Typography>
                </Row>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    height: '54px',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  <RiFlashlightFill
                    size={16}
                    style={{ display: 'inline', marginRight: '4px' }}
                  />
                  {todoForUser}
                  {todosForUser?.length > 1 && (
                    <Box
                      component="span"
                      sx={{ fontWeight: 600, ml: 1 }}
                    >
                      +{todosForUser?.length - 1} more
                    </Box>
                  )}
                </Typography>
              </>
            ) : (
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontSize: '0.8rem',
                  py: 2,
                  height: '110px',
                  overflow: 'hidden',
                }}
              >
                {orgStatusOneSentence ||
                  orgStatusFallback ||
                  relationshipStatusOneSentence ||
                  'No activity yet on this opportunity'}
              </Typography>
            )}
          </Box>
          <Box>
            <Divider />
            <Row sx={{ pt: 1, justifyContent: 'space-between' }}>
              {/*<AvatarRow
          max={6}
          emails={data.primaryPerson}
      />*/}
              {/*<span>{warmthEmoji[data.properties.warmth || 'none']}</span>*/}

              <Row
                sx={{
                  color: (theme) => lighten(theme.palette.text.secondary, 0.4),
                  fontSize: '0.7rem',
                  fontWeight: 500,
                }}
              >
                <RiTimeLine
                  size={16}
                  style={{
                    marginRight: '4px',
                  }}
                />
                {formatTimestampExpectedClose(data?.expectedCloseDate)}
              </Row>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  fontSize: '0.7rem',
                  fontWeight: 500,
                }}
              >
                {pipeline?.hasRevenue && (
                  <>{formatDealAmount(data?.expectedRevenue)}</>
                )}
              </Typography>
            </Row>
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default PipelineBoardCard
