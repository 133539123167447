import { useContext } from 'react'

import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { RiStickyNote2Line } from '@remixicon/react'
import dayjs from 'dayjs'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'

const GET_NOTES_FOR_PERSON = gql`
  query GetNotesForPerson($email: String!, $workspaceId: String!) {
    getNotesForPerson(email: $email, workspaceId: $workspaceId) {
      id
      plainTextValue
      createdAt
    }
  }
`

const SidebarNotes = ({ email }: { email: string }) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { data } = useQuery(GET_NOTES_FOR_PERSON, {
    variables: { email, workspaceId: selectedWorkspace },
  })

  return (
    <List>
      {data?.getNotesForPerson.map((note) => (
        <ListItem key={note.id}>
          <ListItemIcon
            sx={{
              minWidth: '24px',
              width: '24px',
              justifyContent: 'center',
              mr: 2,
            }}
          >
            <RiStickyNote2Line size={32} />
          </ListItemIcon>
          <ListItemText
            primary={note.plainTextValue}
            secondary={dayjs(note.createdAt).format('MM/DD/YYYY')}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            secondaryTypographyProps={{
              noWrap: true,
              variant: 'caption',
            }}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default SidebarNotes
