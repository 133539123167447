import { useContext, useEffect, useState } from 'react'

import { Box, FormControl, TextField } from '@mui/material'
import { RiBarChart2Line } from '@remixicon/react'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import Pipeline, {
  pipelineFilterWidths,
} from 'src/components/Pipeline/Pipeline'
import PipelineChooser from 'src/components/Pipeline/PipelineChooser/PipelineChooser'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

const GET_PIPELINE_TITLES_FOR_OPPORTUNITIES_PAGE = gql`
  query GetPipelineTitlesForOpportunitiesPage($workspaceId: String!) {
    pipelineTitles(workspaceId: $workspaceId) {
      id
      title
    }
  }
`

export const getLocalSelectedPipelineStorageKey = (workspace: string) =>
  `selectedOpportunityPagePipeline_${workspace}`

export const getStoredPipelineId = (workspaceId: string) => {
  if (!workspaceId) return null
  const storedValue = localStorage.getItem(
    getLocalSelectedPipelineStorageKey(workspaceId)
  )
  if (storedValue === 'null' || storedValue === 'undefined' || !storedValue) {
    return null
  }
  return storedValue
}

export const setStoredPipelineId = (
  workspaceId: string,
  pipelineId: string
) => {
  if (!workspaceId) return
  localStorage.setItem(
    getLocalSelectedPipelineStorageKey(workspaceId),
    pipelineId
  )
}

export const removeStoredPipelineId = (workspaceId: string) => {
  if (!workspaceId) return
  localStorage.removeItem(getLocalSelectedPipelineStorageKey(workspaceId))
}

const OpportunitiesPage = () => {
  const { selectedWorkspace } = useContext(DayContext)

  const [selectedPipelineId, setSelectedPipelineId] = useState<string | null>(
    null
  )

  const { data } = useQuery(GET_PIPELINE_TITLES_FOR_OPPORTUNITIES_PAGE, {
    variables: {
      workspaceId: selectedWorkspace,
    },
    skip: !selectedWorkspace,
    onCompleted: ({ pipelineTitles }) => {
      logger.dev({ length: pipelineTitles.length })
      if (pipelineTitles.length === 0) {
        navigate(routes.pipeline())
      }
    },
  })

  const handlePipelineChange = (
    pipeline: { id: string; title: string } | null
  ) => {
    if (pipeline) {
      setSelectedPipelineId(pipeline.id)
      setStoredPipelineId(selectedWorkspace, pipeline.id)
    } else {
      setSelectedPipelineId(null)
      removeStoredPipelineId(selectedWorkspace)
    }
  }

  useEffect(() => {
    const storedPipelineId = getStoredPipelineId(selectedWorkspace)
    if (
      selectedWorkspace &&
      (!selectedPipelineId || storedPipelineId !== selectedPipelineId)
    ) {
      if (storedPipelineId) {
        setSelectedPipelineId(storedPipelineId)
      } else {
        setSelectedPipelineId(data?.pipelineTitles[0]?.id)
      }
    }
  }, [selectedWorkspace, selectedPipelineId, data])

  return (
    <>
      <Metadata
        title="Opportunities & Pipelines"
        description="Opportunities & Pipelines"
      />
      <Box
        sx={{
          height: '100vh',
          width: 'calc(100vw - 64px)',
          background: (theme) => theme.palette.background.default,
        }}
      >
        {selectedPipelineId && selectedWorkspace && (
          <Pipeline
            key={`opportunityPagePipeline_${selectedPipelineId}`}
            workspaceId={selectedWorkspace}
            id={selectedPipelineId}
            actions={
              <FormControl
                sx={{
                  width: pipelineFilterWidths,
                  mr: 1,
                  borderRadius: '4px',
                  height: '32px',
                  p: 0,
                  '& .MuiSelect-select': {
                    p: 0,
                    borderRadius: '4px',
                    height: '30px',
                    alignItems: 'center',
                    border: (theme) =>
                      `1px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                {data ? (
                  <PipelineChooser
                    pipelineTitles={data?.pipelineTitles}
                    selectedPipeline={data?.pipelineTitles?.find(
                      (pipeline) => pipeline.id === selectedPipelineId
                    )}
                    onChangePipeline={handlePipelineChange}
                    label={null}
                    startIcon={<RiBarChart2Line size={14} />}
                    valueTypographyProps={{
                      lineHeight: '140%',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 400,
                    }}
                    selectProps={{
                      disableUnderline: true,
                      variant: 'standard',
                    }}
                  />
                ) : (
                  <TextField
                    label="Pipeline"
                    value="Loading..."
                    disabled={true}
                    fullWidth={true}
                  />
                )}
              </FormControl>
            }
          />
        )}
      </Box>
    </>
  )
}

export default OpportunitiesPage
