import { useContext, useState } from 'react'

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  RiBuilding2Fill,
  RiCloseLine,
  RiShareForwardLine,
  RiStickyNoteAddLine,
} from '@remixicon/react'
import toast from 'react-hot-toast'
import { getDomain } from 'tldts'
import { v4 as uuid } from 'uuid'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import OrganizationPageCreateInstructions from 'src/components/OrganizationPageCreateInstructions/OrganizationPageCreateInstructions'
import OrganizationSocialLinks from 'src/components/Organizations/OrganizationSocialLinks/OrganizationSocialLinks'
import OpportunityDomainChip from 'src/components/Pipeline/OpportunityDomainChip/OpportunityDomainChip'
import SidebarOrganizationOverview from 'src/components/SidebarOrganizationOverview/SidebarOrganizationOverview'
import SlackChannels from 'src/components/SlackChannels/SlackChannels'
import SlackLogo from 'src/components/SlackLogo/SlackLogo'
import WorkspaceUserContextDialog from 'src/components/WorkspaceUserContextDialog/WorkspaceUserContextDialog'
import { DayContext } from 'src/lib/dayContext'
import { ungatedForCrm3 } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import Row from '../../Row/Row'
import { actionChipStyle, sidebarBodyTextStyle } from '../Sidebar'

const GET_PAGES_FOR_ORG_SIDEBAR = gql`
  query GetPagesForOrgSidebar($domain: String!) {
    pagesWithOrg(domain: $domain) {
      id
      title
      emoji
      madeExternalAt
      updatedAt
    }
  }
`

const GET_ORGANIZATION_FOR_ORG_SIDEBAR = gql`
  query GetOrganizationForOrgSidebar($workspaceId: String!, $orgId: String!) {
    organization(id: $orgId, workspaceId: $workspaceId) {
      name
      domain
      subCompanyTag
      description
      aiDescription
      promises
      naicsCodes
      sicCodes
      industry
      employeeCount
      annualRevenue
      funding
      address
      city
      state
      country
      postalCode
      colorVibrant
      colorDarkVibrant
      colorLightVibrant
      colorMuted
      colorDarkMuted
      colorLightMuted
      photoSquare
      photoIcon
      photoBanner
      stockTicker
      socialTwitter
      socialLinkedIn
      socialFacebook
      socialYouTube
      markdown
      createdAt
      updatedAt
      edgarCik
      crunchbaseEntityId
      similarDomains
      resolvedUrl
      opportunities {
        id
        workspaceId
        title
        pipelineId
        pipelineTitle
        stage
        updatedAt
      }
      pages {
        id
        title
      }
      relationshipTypes
      overview
    }
  }
`

const GET_SLACK_CHANNELS = gql`
  query GetSlackChannels($workspaceId: String!, $orgId: String!) {
    slackChannels(workspaceId: $workspaceId, orgId: $orgId) {
      id
      name
      summary
      slackChannelId
      slackIntegration {
        slackTeamId
      }
    }
  }
`

const SidebarLayoutOrganization = ({ crmObject, setOpen }) => {
  const { selectedWorkspace, workAccounts, workspacePeople } =
    useContext(DayContext)
  const [noteCreateOrganizationId, setNoteCreateOrganizationId] = useState(null)
  const [forceRefreshKey, setForceRefreshKey] = useState(uuid())

  const domain = getDomain(`http://${crmObject?.properties?.domain}`)

  const { data: orgData, loading: organizationLoading } = useQuery(
    GET_ORGANIZATION_FOR_ORG_SIDEBAR,
    {
      variables: {
        workspaceId: selectedWorkspace,
        orgId: domain,
      },
      skip: !domain,
    }
  )

  const { data: slackChannelsData } = useQuery(GET_SLACK_CHANNELS, {
    variables: {
      workspaceId: selectedWorkspace,
      orgId: domain,
    },
    skip: !domain || !selectedWorkspace,
    onCompleted: ({ slackChannels }) => {
      logger.dev({ slackChannels })
    },
  })
  const slackChannels = slackChannelsData?.slackChannels

  const organization = orgData?.organization

  const { data: pagesData } = useQuery(GET_PAGES_FOR_ORG_SIDEBAR, {
    variables: {
      domain,
    },
    skip: !domain,
  })

  const mergedCrmObject = {
    objectId: domain,
    objectType: NativeObjectTypes.Organization,
    properties: { ...crmObject.properties, ...organization },
  }

  return (
    <>
      <Row sx={{ justifyContent: 'space-between', pt: 2, px: 3 }}>
        <Row gap={1}>
          {organizationLoading ? (
            <CircularProgress
              color="secondary"
              size={64}
            />
          ) : (
            <>
              {mergedCrmObject?.properties?.photoSquare ? (
                <DomainAvatar
                  domain={mergedCrmObject?.properties?.domain}
                  size={64}
                />
              ) : (
                <RiBuilding2Fill size={24} />
              )}
            </>
          )}
          <Box sx={{ width: '410px', overflow: 'hidden' }}>
            <Row gap={1}>
              <Typography
                variant="h1"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  flexShrink: 1,
                  fontSize: '1.5rem',
                }}
              >
                {mergedCrmObject?.properties?.name || mergedCrmObject?.objectId}
              </Typography>
              <OrganizationSocialLinks
                coreCompany={{ ...mergedCrmObject?.properties }}
              />
            </Row>
            <Typography>{mergedCrmObject?.properties?.domain}</Typography>
          </Box>
        </Row>
        <Row gap={1}>
          <Button
            color="primary"
            variant="outlined"
            sx={{ px: 1, fontSize: '0.7rem', fontWeight: 500 }}
            onClick={() => {
              navigate(
                routes.objectDetail({
                  workspaceId: selectedWorkspace,
                  objectTypeSlug: 'organizations',
                  objectId: mergedCrmObject.objectId,
                })
              )
              setOpen(false)
            }}
          >
            View more
          </Button>
          <IconButton onClick={() => setOpen(false)}>
            <RiCloseLine />
          </IconButton>
        </Row>
      </Row>

      <Row
        sx={{ mt: 1, px: 3 }}
        gap={1}
      >
        <Tooltip title={`Add note to ${mergedCrmObject?.properties?.name}`}>
          <Chip
            clickable={true}
            icon={<RiStickyNoteAddLine size={14} />}
            label="Note"
            variant="outlined"
            size="small"
            sx={{ ...actionChipStyle }}
            onClick={() => {
              setNoteCreateOrganizationId(mergedCrmObject?.properties?.domain)
            }}
          />
        </Tooltip>
        {noteCreateOrganizationId && (
          <WorkspaceUserContextDialog
            onClose={() => {
              setNoteCreateOrganizationId(null)

              setTimeout(() => {
                setForceRefreshKey(uuid())
              }, 1000)
            }}
            title={`Add note to ${mergedCrmObject?.properties?.name}`}
            organizationId={noteCreateOrganizationId}
          />
        )}
        <Tooltip
          title="Copy link to clipboard"
          arrow={true}
        >
          <Chip
            clickable={true}
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.HOST}${routes.objectDetail({
                  workspaceId: selectedWorkspace,
                  objectTypeSlug: 'organizations',
                  objectId: mergedCrmObject.objectId,
                })}`
              )
              toast.success('Link copied to clipboard!')
            }}
            size="small"
            sx={{ ...actionChipStyle }}
            label="Share"
            variant="outlined"
            icon={<RiShareForwardLine size={14} />}
          />
        </Tooltip>
        {ungatedForCrm3({
          selectedWorkspace,
          workAccounts,
        }) && <OrganizationPageCreateInstructions orgId={domain} />}
      </Row>
      <Box sx={{ px: 3, height: 'calc(100vh - 128px)', overflowY: 'auto' }}>
        <Box sx={{ pt: 2, pb: 3 }}>
          <Typography sx={sidebarBodyTextStyle}>
            {mergedCrmObject?.properties?.aiDescription ||
              mergedCrmObject?.properties?.description}
          </Typography>
        </Box>
        <Box
          sx={{
            pt: 2,
            mb: 5,
          }}
        >
          <Row sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h2">Opportunities</Typography>
            <OpportunityDomainChip
              organization={organization}
              openSidebar={true}
            />
          </Row>
        </Box>
        {slackChannels && (
          <Box sx={{ mb: 5 }}>
            <Row sx={{ justifyContent: 'space-between' }}>
              <Typography variant="h2">Slack</Typography>

              {slackChannels?.length === 1 ? (
                <Chip
                  variant="filled"
                  key={slackChannels?.[0].id}
                  icon={
                    <Row sx={{ justifyContent: 'right', width: '20px' }}>
                      <SlackLogo size={14} />
                    </Row>
                  }
                  label={`#${slackChannels?.[0].name}`}
                  onClick={() => {
                    const url = `slack://channel?id=${slackChannels?.[0].slackChannelId}&team=${slackChannels?.[0]?.slackIntegration?.slackTeamId}`
                    window.open(url, '_blank')
                  }}
                  sx={{
                    background: '#703259',
                    color: 'white',
                    fontWeight: '600',
                    fontSize: '12px',
                    letterSpacing: '-0.2px',
                  }}
                />
              ) : null}
            </Row>
            <SlackChannels
              channels={slackChannels}
              includeTitle={false}
            />
          </Box>
        )}
        {organization && (
          <SidebarOrganizationOverview
            refetchKey={forceRefreshKey}
            organization={organization}
            showAbout={false}
          />
        )}
      </Box>
    </>
  )
}

export default SidebarLayoutOrganization
