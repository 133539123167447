import { useContext } from 'react'

import { LinkedIn } from '@mui/icons-material'
import { Avatar, Box, IconButton, Typography } from '@mui/material'
import { Person } from 'types/graphql'

import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import Row from 'src/components/Row/Row'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

const PersonTileMini = ({
  person,
  showSidebar = true,
}: {
  person: Person
  showSidebar?: boolean
}) => {
  const { setSidebarObject } = useContext(DayContext)
  if (!person?.email) return null

  const label = person.fullName || person.email
  const subtitle =
    person.currentJobTitle ||
    person.currentCompanyName ||
    person?.organizations?.[0]?.name ||
    person?.organizations?.[0]?.domain ||
    extractEmailDomain(person.email)
  return (
    <Row
      gap={'12px'}
      sx={{
        width: '100%',
        alignItems: 'top',
        cursor: showSidebar ? 'pointer' : 'default',
      }}
      onClick={() => {
        if (showSidebar) {
          setSidebarObject({
            objectType: NativeObjectTypes.Contact,
            objectId: person.email,
            properties: person,
          })
        }
      }}
    >
      {person.photoUrl ? (
        <Avatar
          src={person.photoUrl}
          sx={{ height: '32px', width: '32px' }}
        />
      ) : (
        <ContactAvatar
          email={person.email}
          size={32}
          borderRadius={100}
          showVerification={false}
          showCompany={false}
        />
      )}
      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <Row gap={1}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '13px',
              letterSpacing: '-0.25px',
              lineHeight: '140%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '100%',
              overflowY: 'visible',
            }}
          >
            {label}
          </Typography>
          {person.linkedInUrl && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                const linkedInUrl = person.linkedInUrl?.startsWith('http')
                  ? person.linkedInUrl
                  : `https://${person.linkedInUrl}`
                window.open(linkedInUrl, '_blank')
              }}
              sx={{ p: 0, flexShrink: 0 }}
            >
              <LinkedIn sx={{ color: '#2867B2', fontSize: '1.3rem' }} />
            </IconButton>
          )}
        </Row>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '11px',
            letterSpacing: '-0.25px',
            lineHeight: '130%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
            overflowY: 'visible',
            color: 'text.secondary',
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Row>
  )
}

export default PersonTileMini
