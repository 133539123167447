import { Avatar } from '@mui/material'
import { RiBuilding2Fill } from '@remixicon/react'

import { routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { extractEmailDomain, isFreemailDomain } from 'src/lib/contactFormatting'
import { getCompanyLogoUrl } from 'src/lib/formatters'

const GET_CORE_COMPANY_FOR_DOMAIN_AVATAR = gql`
  query GetCoreCompanyForDomainAvatar($domain: String!) {
    coreCompany(domain: $domain) {
      id
      name
      photoSquare
      colorVibrant
    }
  }
`

const DomainAvatar = ({
  email,
  size,
  border,
  domain,
  photoUrl,
  borderRadius = 1000,
  companyColor,
  onClick,
  sx = {},
}: {
  email?: string
  size?: number
  border?: number
  domain?: string
  photoUrl?: string
  borderRadius?: number
  companyColor?: string
  onClick?: () => void
  sx?: any
}) => {
  const { currentUser: user } = useAuth()

  const { data: coreCompanyData } = useQuery(
    GET_CORE_COMPANY_FOR_DOMAIN_AVATAR,
    {
      variables: { domain },
      skip: !domain || !!photoUrl || isFreemailDomain(domain),
    }
  )

  if (!domain && email) domain = extractEmailDomain(email)
  if (!domain) domain = ''
  domain = domain.toLowerCase().trim()

  photoUrl = photoUrl || coreCompanyData?.coreCompany?.photoSquare
  companyColor = companyColor || coreCompanyData?.coreCompany?.colorVibrant

  const fontSize: string = size ? `${Math.floor(0.4 * size)}px` : '48px'
  const letter = <RiBuilding2Fill size={size ? Math.floor(0.5 * size) : 36} /> //domain.slice(0, 1)
  if (!border) border = 0
  const overridePhotoUrl = domain
    ? getCompanyLogoUrl({ domain, photoUrl })
    : photoUrl

  return (
    <Avatar
      className="domainAvatar"
      onClick={() => {
        if (onClick) onClick()
        else if (user?.email === 'markitecht@gmail.com') {
          window.open(routes.editCoreCompany({ domain }), '_blank')
        }
      }}
      src={overridePhotoUrl}
      sx={{
        height: `${size}px`,
        width: `${size}px`,
        background: (theme) =>
          overridePhotoUrl
            ? 'transparent'
            : companyColor || theme.palette.divider,
        border: (theme) =>
          `${border}px solid ${theme.palette.divider} !important`,
        borderRadius: `${borderRadius}px`,
        color: (theme) => theme.palette.primary.contrastText,
        fontSize,
        fontWeight: 600,
        textDecoration: 'none',
        '&:not(a)': {
          textDecoration: 'none',
        },
        '& img.MuiAvatar-img': {
          objectFit: 'contain',
        },
        ...sx,
      }}
    >
      {letter}
    </Avatar>
  )
}

export default DomainAvatar
