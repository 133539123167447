import { useContext } from 'react'

import { Box, Chip } from '@mui/material'
import { RiCalendarLine, RiFileTextLine } from '@remixicon/react'
import toast from 'react-hot-toast'
import { Action } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import SlackLogo from 'src/components/SlackLogo/SlackLogo'
import { DayContext } from 'src/lib/dayContext'
import { buildGmailNewDraftUrl } from 'src/lib/gmail'
import { logger } from 'src/lib/logger'

const CREATE_PAGE_FROM_ACTION_LABEL = gql`
  mutation CreatePageFromActionLabel($input: CreatePageFromMarkdownInput!) {
    createPageFromMarkdown(input: $input) {
      id
    }
  }
`

export enum ActionChannelType {
  EMAIL = 'EMAIL',
  GMAIL = 'GMAIL',
  SLACK = 'SLACK',
  MEETING = 'MEETING',
  NOTE = 'NOTE',
  PAGE = 'PAGE',
}

const ActionChannelChip = ({ action }: { action: Action }) => {
  const { currentUser } = useAuth()
  const { selectedWorkspace } = useContext(DayContext)

  const channel = action?.channel

  const [createPage] = useMutation(CREATE_PAGE_FROM_ACTION_LABEL)

  const handleCreatePage = async () => {
    if (selectedWorkspace) {
      toast.promise(
        createPage({
          variables: {
            input: {
              title: action.draft?.title,
              contentMarkdown: action.draft?.body,
              workspaceId: selectedWorkspace,
              domains: action.organizations?.map((o) => o.domain),
              ownerEmail: currentUser?.email,
              people: action.people?.map((p) => p.email),
            },
          },
        }),
        {
          loading: 'Creating page...',
          success: (result) => {
            navigate(
              routes.pageDetail({ id: result.data.createPageFromMarkdown.id })
            )
            return 'Page created'
          },
          error: 'Error creating page',
        }
      )
    }
  }

  const display = {
    icon: null,
    label: null,
    onClick: null,
  }

  let channelTypeId = channel?.type as ActionChannelType
  if (channelTypeId === 'EMAIL') {
    channelTypeId = 'GMAIL' as ActionChannelType
  }

  switch (channelTypeId) {
    case ActionChannelType.GMAIL:
      display.icon = (
        <Box
          component="img"
          src={'/gmail-icon.svg'}
          sx={{
            height: '12px',
            width: '12px',
          }}
        />
      )
      display.label = action?.draft?.title || 'Gmail'
      display.onClick = () => {
        logger.dev({
          channel,
        })
        const url = buildGmailNewDraftUrl({
          to: action.people?.map((p) => p.email).join(','),
          subject: action.draft?.title,
          body: action.draft?.body,
          account: channel.accountId,
        })
        window.open(url, '_blank')
      }
      break
    case ActionChannelType.SLACK:
      display.icon = <SlackLogo size={12} />
      display.label = `#${channel.label}`
      display.onClick = () => {
        logger.dev({
          channel,
        })
        window.open(
          `slack://channel?id=${channel.id}&team=${channel.accountId}`
        )
      }
      break
    case ActionChannelType.MEETING:
      display.icon = <RiCalendarLine size={12} />
      display.label = 'Meeting'
      break
    case ActionChannelType.NOTE:
      display.icon = <RiFileTextLine size={12} />
      display.label = 'Note'
      break
    case ActionChannelType.PAGE:
      display.icon = <RiFileTextLine size={12} />
      display.label = action?.draft?.title || 'Page'
      display.onClick = handleCreatePage
      break
  }

  return (
    <Chip
      icon={display.icon}
      label={display.label}
      onClick={display.onClick}
      clickable={true}
      variant="outlined"
      size="small"
      sx={{
        width: '100%',
        justifyContent: 'left',
        fontWeight: '600',
        fontSize: '11px',
        letterSpacing: '-0.2px',
        '& .MuiChip-label': {
          lineHeight: '100%',
          overflowY: 'visible',
          pl: '4px',
          textOverflow: 'ellipsis',
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
        },
        '& .MuiChip-icon, svg': {
          flexShrink: 0,
          ml: '4px',
          mr: '0px',
        },
      }}
    />
  )
}

export default ActionChannelChip
