import { Box, Typography } from '@mui/material'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import {
  RiAlignJustify,
  RiDownloadCloudLine,
  RiFilter3Line,
  RiSearchLine,
} from '@remixicon/react'
import dayjs from 'dayjs'
import LayoutColumnLineIcon from 'remixicon-react/LayoutColumnLineIcon'

import Row from 'src/components/Row/Row'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { NativeObjectTypes } from 'src/lib/objects'
import { getRelationshipSummaryTypeMetadataFromKey } from 'src/lib/relationshipSummary'

import OrganizationChip from '../Organizations/OrganizationChip/OrganizationChip'
import PersonTileMini from '../People/PersonTileMini/PersonTileMini'
import PrivateToYou from '../PrivateToYou/PrivateToYou'
import RelationshipTypeChip from '../RelationshipTypeChip/RelationshipTypeChip'

export const getDataTableRenderers = ({ setSidebarObject }) => {
  return {
    headers: {
      name: (params) => {
        return (
          <Row sx={{ pl: 3 }}>
            <Box sx={{ fontWeight: 600 }}>{params?.colDef?.headerName}</Box>
          </Row>
        )
      },
    },
    cells: {
      name: (params) => {
        return <PersonTileMini person={params?.row} />
      },
      companyName: (params) => {
        return (
          <OrganizationChip
            domain={extractEmailDomain(params.row?.email)}
            size="small"
            sx={{
              fontWeight: 500,
              '& .domainAvatar': {
                mr: 0,
              },
            }}
          />
        )
      },
      relationshipSummary: (params) => {
        return (
          <Box
            onClick={() =>
              setSidebarObject({
                objectType: NativeObjectTypes.Contact,
                objectId: params?.row?.domain,
                properties: params.row,
              })
            }
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            {params?.row?.relationship?.oneSentence}
          </Box>
        )
      },
      relationshipType: (params) => {
        return <RelationshipTypeChip type={params.row?.relationship?.type} />
      },
      updatedAt: (params) => {
        const formattedDate = params?.row?.updatedAt
          ? dayjs(params.row.updatedAt).format('M/D/YYYY')
          : ''
        return formattedDate
      },
    },
  }
}

export const getDefaultColumnsContact = ({ DataTableRenderers }) => {
  return [
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 0,
      width: 300,
      editable: false,
      renderHeader: DataTableRenderers.headers.name,
      renderCell: DataTableRenderers.cells.name,
      groupable: false,
    },
    {
      field: 'relationshipSummary/oneSentence',
      headerName: 'Current Status',
      flex: 1,
      editable: false,
      groupable: false,
      sortable: false,
      renderCell: DataTableRenderers.cells.relationshipSummary,
      renderHeader: (params) => {
        return (
          <Row sx={{ fontWeight: 500 }}>
            <PrivateToYou sx={{ mr: '6px' }} />
            {params?.colDef?.headerName}
          </Row>
        )
      },
      valueGetter: (params) => {
        return params.row.relationship?.oneSentence
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Activity',
      width: 100,
      editable: false,
      renderCell: DataTableRenderers.cells.updatedAt,
      valueGetter: (params) => {
        return new Date(params.row['updatedAt']).getTime()
      },
    },
    {
      field: 'relationshipSummary/type',
      headerName: 'Type',
      width: 200,
      editable: false,
      renderCell: DataTableRenderers.cells.relationshipType,
      valueGetter: (params) => {
        const typeObject = getRelationshipSummaryTypeMetadataFromKey(
          params.row['relationshipSummary/type']
        )
        return typeObject?.label || ''
      },
    },
    {
      field: 'companyName',
      headerName: 'Organization',
      width: 220,
      editable: false,
      renderCell: DataTableRenderers.cells.companyName,
      valueGetter: (params) => {
        return extractEmailDomain(params.row?.id)
      },
    },
  ]
}

export const DataGridToolbar = (props) => {
  const iconStyle = {
    width: '16px',
    height: '16px',
    fontWeight: 500,
    flexShrink: 0,
  }
  return (
    <GridToolbarContainer
      className="data-grid-toolbar"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        pb: '12px',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        '& .MuiButton-root': {
          mr: 3,
          px: 0,
        },
      }}
    >
      <Row>
        {props.title && (
          <Typography
            variant="h2"
            sx={{ pl: props.titleMargin || 0 }}
          >
            {props.title}
          </Typography>
        )}
        {props.showColumnFilter && (
          <GridToolbarColumnsButton
            startIcon={<LayoutColumnLineIcon style={iconStyle} />}
          />
        )}
        {props.showFilter && (
          <GridToolbarFilterButton
            componentsProps={{
              button: {
                startIcon: <RiFilter3Line style={iconStyle} />,
              },
            }}
          />
        )}
        {props.showDensitySelector && (
          <GridToolbarDensitySelector
            startIcon={<RiAlignJustify style={iconStyle} />}
          />
        )}
        {props.showExport && (
          <GridToolbarExport
            csvOptions={props.csvOptions}
            startIcon={<RiDownloadCloudLine style={iconStyle} />}
          />
        )}
      </Row>
      {props.showQuickFilter && (
        <GridToolbarQuickFilter
          InputProps={{
            startAdornment: <RiSearchLine size={16} />,
            placeholder: 'Search ...',
            disableUnderline: true,
            sx: {
              m: 0,
              p: 0,
              fontSize: '0.8rem',
              fontWeight: 500,
              '& .MuiInput-input': {
                padding: 0,
                fontSize: '0.8rem',
                fontWeight: 500,
              },
              '& .MuiInputBase-adornedStart': {
                marginRight: '4px',
                marginLeft: 0,
              },
            },
          }}
        />
      )}
    </GridToolbarContainer>
  )
}
