import { useMemo } from 'react'

import { Chip } from '@mui/material'
import { RiCalendarLine } from '@remixicon/react'
import { Organization } from 'types/graphql'

import { dayjs } from 'src/lib/dayjs'
import { ellipsize } from 'src/lib/formatters'
import { logger } from 'src/lib/logger'

const OrganizationNextMeetingChip = ({ org }: { org: Organization }) => {
  const nextMeeting = useMemo(() => {
    const nm = org?.overview?.['upcomingEvents']?.[0]
    if (nm) {
      try {
        const startDt = dayjs(parseInt(nm.startTimestamp) * 1000)
        if (startDt.isAfter(dayjs())) {
          return nm
        }
      } catch (e) {
        logger.dev(`Error parsing next meeting start timestamp: ${e}`)
      }
    }
    return null
  }, [org])

  return nextMeeting ? (
    <Chip
      size="small"
      variant="outlined"
      icon={<RiCalendarLine size={14} />}
      label={`Next meeting: "${ellipsize(nextMeeting.title, 20)}" on ${
        nextMeeting.start_time
      }`}
      sx={{
        fontSize: '0.7rem',
        fontWeight: 500,
      }}
    />
  ) : (
    <Chip
      size="small"
      variant="outlined"
      icon={<RiCalendarLine size={14} />}
      label={'No next meeting scheduled'}
      sx={{
        fontSize: '0.7rem',
        fontWeight: 500,
        pl: '4px',
      }}
    />
  )
}

export default OrganizationNextMeetingChip
