import { useContext, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import {
  RiCheckLine,
  RiDeleteBinLine,
  RiMoreLine,
  RiUserSharedLine,
} from '@remixicon/react'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'
import { Action, WorkspaceMember } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import ContactTile from '../ContactTile/ContactTile'
import Row from '../Row/Row'
import WorkspaceMemberSelect from '../WorkspaceMemberSelect/WorkspaceMemberSelect'

const DELETE_ACTION = gql`
  mutation DeleteAction($id: String!, $workspaceId: String!) {
    deleteAction(id: $id, workspaceId: $workspaceId)
  }
`

const COMPLETE_ACTION = gql`
  mutation CompleteAction($id: String!, $workspaceId: String!) {
    completeAction(id: $id, workspaceId: $workspaceId)
  }
`

const REASSIGN_ACTION = gql`
  mutation ReassignAction(
    $id: String!
    $workspaceId: String!
    $reassigneeEmail: String!
    $reassigneeId: String!
  ) {
    reassignAction(
      id: $id
      workspaceId: $workspaceId
      reassigneeEmail: $reassigneeEmail
      reassigneeId: $reassigneeId
    )
  }
`

const ActionActions = ({
  action,
  onUpdate,
  inFocus = false,
  onClose = () => {},
  height = 24,
}: {
  action: Action
  onUpdate: () => void
  inFocus?: boolean
  onClose?: () => void
  height?: number
}) => {
  const confirm = useConfirm()
  const { selectedWorkspace: workspaceId } = useContext(DayContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const [reassignOpen, setReassignOpen] = useState(false)
  const [reassignee, setReassignee] = useState<WorkspaceMember | null>(null)

  const [deleteAction] = useMutation(DELETE_ACTION)
  const [completeAction] = useMutation(COMPLETE_ACTION)
  const [reassignAction] = useMutation(REASSIGN_ACTION)

  const handleDelete = async () => {
    try {
      await confirm({
        title: 'Delete Action?',
        description: 'Are you sure you want to delete this action?',
      })
      toast.promise(
        deleteAction({
          variables: { id: action.id, workspaceId },
        }),
        {
          loading: 'Deleting action...',
          success: () => {
            onUpdate()
            return 'Action deleted'
          },
          error: 'Error deleting action',
        }
      )
    } catch (error) {
      logger.warn(`Error deleting action: ${error}`)
    }
  }

  const handleComplete = () => {
    toast.promise(
      completeAction({
        variables: { id: action.id, workspaceId: action.workspaceId },
      }),
      {
        loading: 'Marking as complete...',
        success: () => {
          onUpdate()
          return 'Action completed'
        },
        error: 'Error completing action',
      }
    )
  }

  const handleReassign = () => {
    toast.promise(
      reassignAction({
        variables: {
          id: action.id,
          workspaceId,
          reassigneeEmail: reassignee.email,
          reassigneeId: reassignee.id,
        },
      }),
      {
        loading: 'Reassigning action...',
        success: () => {
          onUpdate()
          setReassignOpen(false)
          setReassignee(null)
          return 'Action reassigned'
        },
        error: 'Error reassigning action',
      }
    )
  }

  return (
    <Row sx={{ height: `${height}px` }}>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <RiMoreLine />
      </IconButton>
      <Menu
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{
          p: 0,
          '& .MuiTypography-root': {
            fontSize: '12px',
            fontWeight: 500,
            letterSpacing: '-0.3px',
            color: 'text.secondary',
          },
        }}
      >
        <MenuItem
          onClick={handleComplete}
          sx={{ p: 0, pr: 3 }}
        >
          <Row>
            <IconButton>
              <RiCheckLine size={16} />
            </IconButton>
            <Typography>Complete</Typography>
          </Row>
        </MenuItem>
        <MenuItem
          onClick={() => setReassignOpen(true)}
          sx={{ p: 0, pr: 3 }}
        >
          <Row>
            <IconButton>
              <RiUserSharedLine size={16} />
            </IconButton>
            <Typography>Reassign</Typography>
          </Row>
        </MenuItem>
        <MenuItem
          onClick={handleDelete}
          sx={{ p: 0, pr: 3 }}
        >
          <Row>
            <IconButton>
              <RiDeleteBinLine size={16} />
            </IconButton>
            <Typography>Delete</Typography>
          </Row>
        </MenuItem>
      </Menu>
      <Dialog
        open={inFocus}
        onClose={onClose}
      >
        <DialogTitle>{action.title}</DialogTitle>
        <DialogContent>
          <Typography>{action.title}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {}}>do stuff</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={reassignOpen}
        onClose={() => setReassignOpen(false)}
      >
        <DialogTitle>Reassign Action</DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              p: 2,
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 10,
              fontWeight: 500,
              letterSpacing: '-0.3px',
              mb: 3,
            }}
          >
            {`"${action.title}"`}
          </Typography>
          <Box sx={{ pt: 1 }}>
            {reassignee ? (
              <ContactTile email={reassignee.email} />
            ) : (
              <WorkspaceMemberSelect
                onSelect={(members) => {
                  logger.dev({ members })
                  setReassignee(members?.[0])
                }}
                value={[]}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!reassignee}
            onClick={handleReassign}
            size="large"
            variant={reassignee ? 'contained' : 'outlined'}
            disableElevation={true}
            fullWidth={true}
          >
            Reassign
          </Button>
        </DialogActions>
      </Dialog>
    </Row>
  )
}

export default ActionActions
