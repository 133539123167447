import { useState } from 'react'

import { Box, List, ListItem, Typography, useTheme } from '@mui/material'
import { RiCircleFill, RiCircleLine } from '@remixicon/react'
import { Action } from 'types/graphql'

import { useAuth } from 'src/auth'

import ActionActions from '../ActionActions/ActionActions'
import ActionChannelChip from '../Actions/ActionChannelChip/ActionChannelChip'
import ContactTile from '../ContactTile/ContactTile'
import Row from '../Row/Row'

const SidebarActions = ({
  actions,
  onUpdate,
}: {
  actions: Action[]
  onUpdate: () => void
}) => {
  const { currentUser } = useAuth()
  const theme = useTheme()
  const [openAction, setOpenAction] = useState<Action | null>(null)

  if (!Array.isArray(actions)) return null

  const actionsByAssignee = {}
  for (const action of actions) {
    if (action.owner?.email) {
      if (!actionsByAssignee[action.owner.email]) {
        actionsByAssignee[action.owner.email] = [] as Action[]
      }
      actionsByAssignee[action.owner.email].push(action)
    }
  }
  const assignees: [string, Action[]][] = Object.entries(actionsByAssignee)

  return (
    <>
      {assignees.map(([assignee, actions]) => (
        <Box
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 3,
          }}
          key={`sidebar-actions-assignee-${assignee}`}
        >
          <ContactTile email={assignee} />
          <List
            sx={{
              px: 0,
              py: 2,
            }}
          >
            {actions.map((action, index) => (
              <ListItem
                key={`${action.id}-${assignee}-${index}`}
                sx={{ padding: 0, minHeight: '48px', pb: 3 }}
              >
                <Row
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'top',
                    width: '100%',
                  }}
                >
                  <Row gap={2}>
                    <Row>
                      {action?.priority === 'URGENT' ? (
                        <RiCircleFill
                          size={10}
                          color={theme.palette.error.light}
                        />
                      ) : (
                        <>
                          {action?.priority === 'HIGH' ? (
                            <RiCircleFill
                              size={10}
                              color={theme.palette.secondary.main}
                            />
                          ) : (
                            <RiCircleLine size={10} />
                          )}
                        </>
                      )}
                    </Row>
                    <Typography
                      variant="body2"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setOpenAction(action)}
                    >
                      {action.title}
                    </Typography>
                  </Row>
                  <Row
                    sx={{ alignItems: 'top' }}
                    gap={1}
                  >
                    <Box sx={{ maxWidth: '160px', minWidth: '96px' }}>
                      <ActionChannelChip action={action} />
                    </Box>
                    <ActionActions
                      action={action}
                      onUpdate={onUpdate}
                      inFocus={openAction?.id === action.id}
                      onClose={() => setOpenAction(null)}
                    />
                  </Row>
                </Row>
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </>
  )
}

export default SidebarActions
